import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
// import PostCard from "../components/post-card"
// import SEO from "../components/seo"

// Utilities
import kebabCase from "lodash/kebabCase"
import { RiHome2Line } from "react-icons/ri"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

const categoryPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout className="blog-page">

    <Helmet title={"カテゴリーリスト | " +title} />
    <div>
      <h1 className="taxonomy-header">カテゴリーリスト</h1>
      <ul className="categories-list">
        {group.map(category => (
          <li key={category.fieldValue}>
            <Link to={`/cat/${kebabCase(category.fieldValue)}/`}>
              {category.fieldValue} <span>{category.totalCount}</span>
            </Link>
          </li>

        ))}
      </ul>
      <div className="back-to-home">
        <Link to="/"><RiHome2Line />サイトトップへ</Link>
      </div>
    </div>
    </Layout>
)

categoryPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default categoryPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`